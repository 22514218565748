import './index.scss'

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['expenses', 'expensesDialog', 'mileage', 'mileageDialog', 'mileageInput']

  addExpenses(event) {
    event.preventDefault()
    this.expensesDialogTarget.showModal()
  }

  addMileage(event) {
    event.preventDefault()
    this.mileageDialogTarget.showModal()
  }

  cancelAddExpenses(event) {
    event.preventDefault()
    this.expensesDialogTarget.close()
  }

  cancelAddMileage(event) {
    event.preventDefault()
    let miles = this.mileageTarget.innerHTML
    this.mileageInputTarget.value = miles.split(' ')[0]
    this.mileageDialogTarget.close()
  }

  doneAddMileage(event) {
    event.preventDefault()
    let miles = parseInt(this.mileageInputTarget.value)
    this.mileageTarget.innerHTML = this.pluralize(miles, 'mile')
    this.mileageDialogTarget.close()
  }

  pluralize(count, noun, suffix = 's') {
    return `${count} ${noun}${count !== 1 ? suffix : ''}`
  }
}
