import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'inverse', 'fieldset', 'remove']
  static values = {
    option: String
  }

  connect() {
    this.toggle()
  }

  toggle() {
    this.fieldsetTarget.style.display = 'none'
    if (this.hasInverseTarget) { this.inverseTarget.style.display = 'grid' }

    this.checkboxTargets.forEach((el) => {
      if (el.checked && el.value === this.optionValue) {
        this.fieldsetTarget.style.display = 'grid'
        if (this.hasRemoveTarget) { this.removeTarget.remove() }
      } else if (this.hasInverseTarget) {
        this.inverseTarget.style.display = 'none'
      }
    })
  }
}
