import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']
  static outlets = ['change-notification']

  connect() {
    this.formData = this._getCurrentData()
    this.timeout = null
  }

  autoSubmit() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
      this.markClean()
    }, 5000)
  }

  check() {
    if (this.hasUnsavedChanges()) this.markDirty()
  }

  submit(event) {
    clearTimeout(this.timeout)
    const submitter = event.detail.formSubmission.submitter
    if (submitter && submitter.name === 'back') return event.returnValue

    if (submitter == null) return event.returnValue
    if (event.detail.success) this.markClean()

    return event.returnValue
  }

  changeNotificationOutletConnected() {
    this.check()
  }

  hasUnsavedChanges() {
    const currentData = this._getCurrentData()

    const differences = Object.entries(this.formData).reduce((accum, [key, value]) => {
      if (!(key in currentData) || currentData[key] !== value) {
        accum.push(key)
      }

      return accum
    }, [])

    return differences.length > 0
  }

  markDirty() {
    if (!this.hasChangeNotificationOutlet) return

    this.changeNotificationOutlet.markDirty()
  }

  markClean() {
    if (!this.hasChangeNotificationOutlet) return

    this.changeNotificationOutlet.markClean()
  }

  save() {
    if (this.hasUnsavedChanges()) {
      this.markDirty()
      this.autoSubmit()
    } else {
      this.markClean()
    }
  }

  _getCurrentData() {
    const formData = new FormData(this.formTarget)
    const data = []

    Array.from(formData.entries()).forEach(([name, value]) => {
      if (
        name !== '_method' &&
        name !== 'authenticity_token' &&
        !name.match(/\[ssn\]/) &&
        !name.match(/\[attachment\]/) &&
        !name.match(/\[proof_attributes\]/)
      ) {
        data.push([name, value])
      }
    })

    return Object.fromEntries(data)
  }
}
