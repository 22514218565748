import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'cell', 'checkbox', 'table' ]

  markComplete(event) {
    const month = event.target.dataset.month
    this.dispatch('markComplete', { detail: { month: month } })
  }
}
